import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CollapseSection from '~/components/collapse/CollapseSection';
import DatetimeInput from '~/components/input/DatetimeInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import FormKho from '../../kho/FormKho';
import FormNCC from '../../ncc/FormNCC';
import FormPTTT from '../../pttt/FormPTTT';
import { LABEL_KHO } from '~/utils/label.constant';

function InfoSection({ register, errors, control, setValue }) {
  const storeData = useSelector((state) => state.store);

  useEffect(() => {
    if (storeData && storeData.data.length === 1) {
      setValue('kho', storeData.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            type="text"
            label="Số chứng từ"
            placeholder="Nhập hoặc tạo tự động"
            name="so_ct"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ngay_ct"
            render={({ field: { onChange, value } }) => (
              <DatetimeInput
                label="Ngày chứng từ"
                labelWidth="30%"
                value={value}
                onChange={onChange}
                errorMessage={errors?.ngay_ct?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="kho"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                label={LABEL_KHO}
                required
                apiCode="dmkho"
                placeholder={`Chọn ${LABEL_KHO}`}
                searchFileds={['ma_kho', 'ten_kho']}
                condition={{ status: true }}
                getOptionLabel={(option) => option.ten_kho}
                selectedValue={value}
                value={value || { ma_kho: '', ten_kho: '' }}
                onSelect={onChange}
                errorMessage={errors?.kho?.message}
                FormAdd={FormKho}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="nha_cung_cap"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                required
                labelWidth="30%"
                label="Nhà cung cấp"
                apiCode="customer"
                placeholder="Chọn nhà cung cấp"
                searchFileds={['ma_kh', 'ten_kh']}
                condition={{ kh_yn: false, ncc_yn: true }}
                getOptionLabel={(option) => option.ten_kh}
                selectedValue={value}
                value={value || { ma_kh: '', ten_kh: '' }}
                onSelect={onChange}
                errorMessage={errors?.nha_cung_cap?.message}
                FormAdd={FormNCC}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="trang_thai"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Trạng thái"
                apiCode="trangthai"
                placeholder="Trạng thái phiếu"
                searchFileds={['ten_trang_thai']}
                getOptionLabel={(option) => option.ten_trang_thai}
                selectedValue={value}
                value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
                onSelect={onChange}
                withIdApp={false}
                condition={{ ma_ct: 'PN1', status: true }}
                errorMessage={errors?.trang_thai?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="pttt"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="PT thanh toán"
                apiCode="ptthanhtoan"
                placeholder="Chọn phương thức thanh toán"
                searchFileds={['ten']}
                condition={{ status: true }}
                getOptionLabel={(option) => option.ten}
                selectedValue={value}
                value={value || { _id: '', ten: '' }}
                onSelect={onChange}
                FormAdd={FormPTTT}
              />
            )}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
