import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';

const schema = yup.object({
  ma_ca: yup.string().required(),
  ten_ca: yup.string().required(),
});

const defaultData = { ma_ca: '', ten_ca: '' };

export default function FormCa({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues || defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmca',
      data: values,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="800px"
      title="ca"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <TextInput
            required
            readOnly={isEdit}
            labelWidth="30%"
            label="Mã ca"
            placeholder="Nhập mã ca"
            name="ma_ca"
            register={register}
            errorMessage={errors?.ma_ca?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Tên ca"
            placeholder="Nhập tên ca"
            name="ten_ca"
            required
            register={register}
            errorMessage={errors?.ten_ca?.message}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
}
