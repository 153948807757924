import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterTimeFromTo from '../FilterTimeFromTo';
import moment from 'moment';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import FilterSelectApi from '../FilterSelectApi';
import FilterContainer from '../FilterContainer';
import { LABEL_KHO } from '~/utils/label.constant';

function FilterBaoCaoSoQuy({
  setQueryObject = () => {},
  setConcern = () => {},
}) {
  const originFilter = {
    timeFrom: moment().startOf('months').format('YYYY-MM-DD'),
    timeTo: moment().format('YYYY-MM-DD'),
    kho: null,
  };
  const [timeOption, setTimeOption] = useState(null);
  const [filter, setFilter] = useState(originFilter);
  const [mode, setMode] = useState(1);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const queryObject = {
      tk: 111,
      groupBy: 'so_ct',
    };
    const concern = {
      api: 'soquy',
      convertData: (data) => {
        // data.splice(-2, 0, data.shift());
        // data.shift();
        const dauKy = data.shift();
        data.splice(0, 1);
        const tongPhatSinh = data.splice(-2, 1);
        const cuoiKy = data.pop();

        const result = data.map((item) => {
          return {
            so_ct: item.so_ct,
            ngay_ct: item.ngay_ct,
            ma_kho: item.ma_kho,
            ma_ct: item.ma_ct,
            tien_thu: item.ps_no_nt,
            tien_chi: item.ps_co_nt,
          };
        });

        result.unshift({
          ma_kho: 'Đầu kỳ',
          tien_thu: dauKy.ps_no_nt,
          tien_chi: dauKy.ps_co_nt,
        });
        result.push({
          ma_kho: 'Phát sinh trong kỳ',
          tien_thu: tongPhatSinh.ps_no_nt,
          tien_chi: tongPhatSinh.ps_co_nt,
        });
        result.push({
          ma_kho: 'Cuối kỳ',
          tien_thu: cuoiKy.ps_no_nt,
          tien_chi: cuoiKy.ps_co_nt,
        });
        return result;
      },
      columns: [
        {
          name: 'Số CT',
          selector: (row) => row.so_ct,
          width: '120px',
          wrap: true,
        },
        {
          name: 'Ngày CT',
          selector: (row) => row.ngay_ct,
          format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
          width: '120px',
          wrap: true,
        },
        {
          name: 'Loại CT',
          selector: (row) => row.ma_ct,
          wrap: true,
          width: '130px',
          center: true,
        },
        {
          name: LABEL_KHO,
          selector: (row) => row.ma_kho,
          wrap: true,
        },
        {
          name: 'Tiền thu',
          selector: (row) => row.tien_thu,
          format: (row) => numeralCustom(row.tien_thu).format(),
          center: true,
          wrap: true,
        },
        {
          name: 'Tiền chi',
          selector: (row) => row.tien_chi,
          format: (row) => numeralCustom(row.tien_chi).format(),
          center: true,
          wrap: true,
        },
        {
          name: 'Diễn giải',
          selector: (row) => row.dien_giai,
          wrap: true,
        },
      ],
    };
    if (filter.timeFrom) {
      queryObject.tu_ngay = filter.timeFrom;
    }
    if (filter.timeTo) {
      queryObject.den_ngay = filter.timeTo;
    }
    if (filter.kho) {
      queryObject.ma_kho = filter.kho.ma_kho;
    }

    setConcern(concern);
    setQueryObject(queryObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOption, filter]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack spacing="10px">
        <FilterSelectApi
          title={LABEL_KHO}
          placeholder={`Chọn ${LABEL_KHO}`}
          apiCode="dmkho"
          selectedValue={filter.kho}
          value={filter.kho || { ma_kho: '', ten_kho: '' }}
          searchFileds={['ma_kho', 'ten_kho']}
          getOptionLabel={(option) =>
            option.ten_kho ? `${option.ten_kho} (${option.ma_kho})` : ''
          }
          onSelect={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterTimeFromTo
          title="Thời gian"
          defaultTimeFrom={filter.timeFrom}
          defaultTimeTo={filter.timeTo}
          showOptions
          onSearch={(time) => setFilter({ ...filter, ...time })}
          onOptionChange={setTimeOption}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterBaoCaoSoQuy;
