import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import CollapseSection from '~/components/collapse/CollapseSection';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { isArray } from 'lodash';
import { v4 } from 'uuid';
import FileIcon from '~/components/file/FileIcon';
import { formatDateDisplay } from '~/utils/helpers';
import { BsDownload, BsTrash } from 'react-icons/bs';
import { saveAs } from 'file-saver';
import ButtonBase from '~/components/button/ButtonBase';
import { MdAttachFile } from 'react-icons/md';
import { cloneDeep } from 'lodash';
import useConfirmContext from '~/hooks/hookContext/useConfirmContext';

function DinhKemSection({
  show,
  setCollapses,
  data,
  attaches,
  setAttaches,
  files,
  setFiles,
}) {
  const showAlert = useAlertContext();
  const showConfirm = useConfirmContext();
  const { asyncGetList, asyncPostData, asyncDelete } = useApisContext();
  const [load, setLoad] = useState(0);

  const inputRef = useRef();

  const handleDownload = async (attach) => {
    try {
      if (!attach) return;
      // const respFile = await axiosPrivate.get(
      //   `/${ID_APP}/file/download/${attach._id}`
      // );
      const respFile = await asyncPostData({
        method: 'get',
        apiCode: 'file',
        endpoint: `/download/${attach._id}`,
        data: {
          responseType: 'blob',
        },
      });
      saveAs(respFile, attach.file?.originalname);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    setFiles((prev) => [...prev, selectedFile]);
  };

  const handleDeleteSavedFile = async (attach) => {
    try {
      const resp = await asyncDelete({
        apiCode: 'file',
        uniqueValue: attach._id,
      });
      if (resp._id) {
        showAlert({
          type: 'success',
          message: `Đã xóa đính kèm ${attach.file?.originalname}`,
        });
        setLoad(load + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || error?.error || 'Lôi khi xóa tệp đính kèm',
      });
    }
  };
  const handleDeleteFile = (index) => {
    setFiles((prev) => {
      const cloneFiles = cloneDeep(prev);
      cloneFiles.splice(index, 1);
      return cloneFiles;
    });
  };

  const getDinhKems = async () => {
    try {
      const resp = await asyncGetList({
        apiCode: 'file',
        condition: { page: 1, limit: 99999, q: { id_link: data?._id } },
      });
      if (isArray(resp)) {
        setAttaches(resp);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || error?.error || 'Lỗi khi tải tệp đính kèm',
      });
    }
  };

  useEffect(() => {
    if (data?._id) {
      getDinhKems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?._id, load]);

  return (
    <CollapseSection
      title="Đính kèm"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, dinh_kem: !prev.dinh_kem }))
      }
    >
      <input
        ref={inputRef}
        type="file"
        hidden
        accept=".jpg,.jpeg,.png,.gif,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx"
        onChange={handleFileChange}
      />
      <Stack sx={{ p: 1 }} alignItems="flex-start" gap={1}>
        <ButtonBase
          variant="outlined"
          startIcon={<MdAttachFile style={{ fontSize: '14px' }} />}
          onClick={() => {
            inputRef?.current?.click();
          }}
        >
          Thêm đính kèm
        </ButtonBase>
        <Grid container spacing={2}>
          {isArray(attaches) &&
            attaches.length > 0 &&
            attaches.map((attach) => {
              const file = attach.file;
              return (
                <Grid key={v4()} item xs={12} sm={6} md={4}>
                  <Stack
                    gap={1}
                    direction="row"
                    sx={{
                      p: 1,
                      pb: 2,
                      borderRadius: 1,
                      border: '1px dashed',
                      borderColor: 'primary.main',
                    }}
                  >
                    <Stack gap={1}>
                      <FileIcon extension={file?.extension || 'docx'} />
                    </Stack>
                    <Stack sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: 'primary.main',
                          lineBreak: 'anywhere',
                        }}
                      >
                        {file.originalname}
                      </Typography>
                      <Typography>
                        <Typography component="span" sx={{ fontWeight: 600 }}>
                          Người tạo:
                        </Typography>{' '}
                        {attach.user_created}
                      </Typography>
                      <Typography>
                        <Typography component="span" sx={{ fontWeight: 600 }}>
                          Ngày tạo:
                        </Typography>{' '}
                        {formatDateDisplay(
                          attach.date_created,
                          'DD/MM/YYYY HH:mm'
                        )}
                      </Typography>
                      <Stack direction="row" gap={1}>
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{ mt: 1, width: '50%' }}
                          endIcon={<BsTrash size={14} />}
                          onClick={() =>
                            showConfirm({
                              title: 'Xác nhận xóa',
                              content: (
                                <Box sx={{ padding: '0 10px' }}>
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    Bạn có chắc muốn xóa đính kèm này không ?
                                  </Typography>
                                </Box>
                              ),
                              onConfirm: () => handleDeleteSavedFile(attach),
                            })
                          }
                          size="small"
                        >
                          Xóa
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ mt: 1, width: '50%', color: 'common.white' }}
                          endIcon={<BsDownload size={14} />}
                          onClick={() => handleDownload(attach)}
                          size="small"
                        >
                          Tải về
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}
          {isArray(files) &&
            files.length > 0 &&
            files.map((file, index) => {
              const extension = file.name.split('.').pop();
              return (
                <Grid key={v4()} item xs={12} sm={6} md={4}>
                  <Stack
                    gap={1}
                    direction="row"
                    sx={{
                      p: 1,
                      pb: 2,
                      borderRadius: 1,
                      border: '1px dashed',
                      borderColor: 'primary.main',
                    }}
                  >
                    <Stack gap={1}>
                      <FileIcon extension={extension || 'docx'} />
                    </Stack>
                    <Stack sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: 'primary.main',
                          lineBreak: 'anywhere',
                        }}
                      >
                        {file.name}
                      </Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ mt: 1, width: '50%' }}
                        endIcon={<BsTrash size={14} />}
                        onClick={() => handleDeleteFile(index)}
                        size="small"
                      >
                        Xóa
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}
        </Grid>
      </Stack>
    </CollapseSection>
  );
}

export default DinhKemSection;
