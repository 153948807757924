import React from 'react';
import { Box } from '@mui/material';
import { FileIcon as FileIconReact, defaultStyles } from 'react-file-icon';

function FileIcon({ extension, wrapperSx = {} }) {
  return (
    <Box sx={{ width: '50px', height: '50px', ...wrapperSx }}>
      <FileIconReact extension={extension} {...defaultStyles[extension]} />
    </Box>
  );
}

export default FileIcon;
