import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import TextInput from '../input/TextInput';
import { LABEL_KHO } from '~/utils/label.constant';
import { numeralCustom } from '~/utils/helpers';
import DatetimeInput from '../input/DatetimeInput';
import moment from 'moment';
import { FaRegCheckCircle } from 'react-icons/fa';
import TableDisplay from '../table/TableDisplay';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useConfirmContext from '~/hooks/hookContext/useConfirmContext';
import useAuthorize from '~/hooks/useAuthorize';
import ButtonBase from '../button/ButtonBase';
import { IoMdTrash } from 'react-icons/io';
import { MdRecycling } from 'react-icons/md';
import { IoPrint } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_URL, ID_APP } from '~/utils/constants';
import useToken from '~/hooks/useToken';
import { useNavigate } from 'react-router-dom';

function ExpandPBL({ data, setLoad, openForm, hideAction }) {
  const token = useToken();
  const { currentStore } = useSelector((state) => state.store);
  const showAlert = useAlertContext();
  const showConfirm = useConfirmContext();
  const { asyncDelete } = useApisContext();
  const allowAuthorize = useAuthorize();
  const [printFrame, setPrintFrame] = useState(null);

  const navigate = useNavigate();

  const handlePrint = async () => {
    try {
      setPrintFrame(null);
      const mauIn = (currentStore?.printers || []).find(
        (item) => item.ma_cn?.toLowerCase() === 'pbl'
      );
      if (!mauIn) {
        showAlert({
          type: 'warning',
          message: `${LABEL_KHO} hiện tại chưa có mẫu in`,
        });
        return;
      }
      const idMauIn = mauIn.id_mau_in;
      const printUrl = `${API_URL}/${ID_APP}/pbl/excel/${idMauIn}?_id=${data._id}&print=1&access_token=${token}`;
      const resp = await axios.get(printUrl);
      let content;
      if (resp && resp.status === 200) {
        content = resp.data;
      }
      return new Promise(async (resolve) => {
        let printFrame = (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe
            onLoad={() => {
              let fr = window.frames['printframe'];
              fr.focus();
              fr.print();
              setTimeout(() => {
                resolve();
              }, 10);
            }}
            style={{ display: 'none' }}
            name="printframe"
            srcDoc={content}
          ></iframe>
        );
        setPrintFrame(printFrame);
      });
    } catch (error) {
      showAlert({
        type: 'error',
        message:
          error?.response?.data?.error ||
          error?.message ||
          error?.error ||
          'Lỗi khi in hóa đơn',
      });
    }
  };

  const handleDelete = async () => {
    try {
      const resp = await asyncDelete({
        apiCode: 'pbl',
        uniqueValue: data._id,
      });
      if (!resp?.error) {
        setLoad((prev) => prev + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong',
      });
    }
  };

  return (
    <>
      {printFrame}
      <Box
        sx={{
          padding: '20px',
          backgroundColor: 'whitish.graySoft',
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: '0 0 10px 10px',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Grid container spacing="20px">
          <Grid item xs={12} md={5}>
            <Stack spacing="20px">
              <TextInput
                readOnly
                labelWidth="40%"
                label="Số chứng từ"
                value={data.so_ct}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label={LABEL_KHO}
                value={data.ten_kho || 'Chưa xác định'}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Nhân viên"
                value={data.ten_nv || data.ma_nv}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Khách hàng"
                value={data?.ten_kh ? `${data.ten_kh} (${data.ma_kh})` : ''}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing="20px">
              <DatetimeInput
                labelWidth="40%"
                label="Ngày chứng từ"
                readOnly
                value={moment(data.ngay_ct).format('YYYY-MM-DD HH:mm')}
              />
              <DatetimeInput
                labelWidth="40%"
                label="Ngày tạo"
                readOnly
                value={moment(data.date_created).format('YYYY-MM-DD HH:mm')}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Người tạo"
                value={data.user_created}
              />
              <DatetimeInput
                labelWidth="40%"
                label="Ngày cập nhật cuối"
                readOnly
                value={moment(data.date_updated).format('YYYY-MM-DD HH:mm')}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Người cập nhật cuối"
                value={data.user_updated}
              />
            </Stack>
          </Grid>
        </Grid>
        {/* ghi chú */}
        <Stack
          direction="row"
          alignItems="flex-start"
          spacing="10px"
          sx={{ marginTop: '20px' }}
        >
          <Typography sx={{ fontWeight: 600 }}>Ghi chú:</Typography>
          <Typography>{data.dien_giai}</Typography>
        </Stack>
        {/* chương trình áp dụng */}
        {data?.exfields?.dmckhd?.length > 0 && (
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>
              Chương trình áp dụng:
            </Typography>
            <List sx={{ padding: '5px 0' }}>
              {data?.exfields?.dmckhd.map((item) => (
                <ListItem key={item._id} sx={{ padding: 0 }}>
                  <ListItemIcon
                    sx={{ minWidth: '20px', color: 'success.main' }}
                  >
                    <FaRegCheckCircle size={16} />
                  </ListItemIcon>
                  <ListItemText sx={{ margin: 0 }}>
                    {item.ten_chietkhau}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Stack>
        )}
        {/* chi tiết hóa đơn */}
        {data?.details?.length > 0 && (
          <Stack spacing="5px" sx={{ marginTop: '20px' }}>
            <Typography sx={{ fontWeight: 600 }}>Chi tiết đơn hàng:</Typography>
            <TableDisplay
              columns={[
                {
                  name: 'Hàng hóa',
                  selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
                  cell: (row) => {
                    return (
                      <Box>
                        <Typography>
                          {row.ten_vt} ({row.ma_vt})
                          {row.ma_lo && (
                            <Chip
                              sx={{
                                backgroundColor: 'secondary.main',
                                color: 'whitish.pureWhite',
                              }}
                              label={row.ma_lo}
                              size="small"
                              component="span"
                            />
                          )}
                        </Typography>
                        {row.dien_giai && (
                          <Typography>Ghi chú: {row.dien_giai}</Typography>
                        )}
                      </Box>
                    );
                  },
                  minWidth: '200px',
                  wrap: true,
                },
                {
                  name: 'Đơn vị tính',
                  selector: (row) => row.ma_dvt,
                  width: '100px',
                  wrap: true,
                  center: true,
                },
                {
                  name: 'Giá gốc',
                  selector: (row) => row.gia_ban_le_goc,
                  format: (row) => numeralCustom(row.gia_ban_le_goc).format(),
                  wrap: true,
                  width: '120px',
                  center: true,
                },
                {
                  name: 'Tiền CK',
                  selector: (row) => row.tien_ck_nt / row.sl_xuat || 1,
                  format: (row) =>
                    numeralCustom(row.tien_ck_nt / row.sl_xuat || 1).format(),
                  width: '120px',
                  center: true,
                },
                {
                  name: 'Đơn giá',
                  selector: (row) => row.gia_ban_nt,
                  format: (row) => numeralCustom(row.gia_ban_nt).format(),
                  wrap: true,
                  width: '120px',
                  center: true,
                },
                {
                  name: 'Số lượng',
                  selector: (row) => row.sl_xuat,
                  width: '80px',
                  center: true,
                },
                {
                  name: 'Thuế suất (%)',
                  selector: (row) => row.thue_suat,
                  format: (row) => numeralCustom(row.thue_suat).format(),
                  width: '120px',
                  center: true,
                },
                {
                  name: 'Thành tiền',
                  selector: (row) => row.tien_xuat_nt,
                  format: (row) => numeralCustom(row.tien_xuat_nt).format(),
                  width: '120px',
                  center: true,
                },
              ]}
              data={data.details || []}
            />
          </Stack>
        )}
        {/* tổng cộng */}
        {data?.details?.length > 0 && (
          <Box sx={{ marginTop: '10px' }}>
            <Grid container>
              <Grid item xs={12} md={7}></Grid>
              <Grid item xs={12} md={5}>
                <Stack spacing="10px">
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Tổng tiền hàng:"
                    value={numeralCustom(data.t_tien_nt).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Chiết khấu sản phẩm:"
                    value={numeralCustom(
                      (data.t_ck_nt || 0) - (data.tien_ck_hd || 0)
                    ).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Chiết khấu hóa đơn:"
                    value={numeralCustom(data.tien_ck_hd).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Tổng chiết khấu:"
                    value={numeralCustom(data.t_ck_nt).format()}
                  />
                  <TextInput
                    readOnly
                    labelWidth="40%"
                    label="Thành tiền:"
                    value={numeralCustom(data.t_tt_nt).format()}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
        {!hideAction && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing="10px"
            sx={{ marginTop: '20px' }}
          >
            <ButtonBase
              onClick={handlePrint}
              startIcon={<IoPrint size={14} />}
              sx={{
                backgroundColor: 'secondary.main',
                '&:hover': { backgroundColor: 'secondary.main' },
              }}
            >
              In
            </ButtonBase>
            {data?.trang_thai &&
              Number(data.trang_thai) === 0 &&
              allowAuthorize({ type: 'update', module: 'pbl' }) && (
                <ButtonBase
                  onClick={() => navigate('/order', { state: data })}
                  startIcon={<MdRecycling size={14} />}
                  sx={{
                    backgroundColor: 'secondary.main',
                    '&:hover': { backgroundColor: 'secondary.main' },
                  }}
                >
                  Xử lý đơn
                </ButtonBase>
              )}
            {allowAuthorize({ type: 'delete', module: 'pbl' }) && (
              <ButtonBase
                startIcon={<IoMdTrash size={14} />}
                sx={{
                  backgroundColor: 'error.main',
                  '&:hover': { backgroundColor: 'error.main' },
                }}
                onClick={() => {
                  showConfirm({
                    title: 'Xác nhận xóa',
                    content: (
                      <Box sx={{ padding: '0 10px' }}>
                        <Typography
                          sx={{ fontSize: '14px', textAlign: 'center' }}
                        >
                          Bạn có chắc muốn xóa dòng này không ?
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            textAlign: 'center',
                            fontStyle: 'italic',
                            color: 'primary.main',
                            marginTop: '10px',
                          }}
                        >
                          Lưu ý: Dữ liễu đã xóa sẽ không thể khôi phục.
                        </Typography>
                      </Box>
                    ),
                    onConfirm: handleDelete,
                  });
                }}
              >
                Xóa
              </ButtonBase>
            )}
          </Stack>
        )}
      </Box>
    </>
  );
}

export default ExpandPBL;
