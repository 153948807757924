import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { numeralCustom, postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import SelectApiInput from '~/components/input/SelectApiInput';
import FormNhanVien from '../nhanvien/FormNhanVien';
import moment from 'moment';
import { LABEL_KHO } from '~/utils/label.constant';
import FormKho from '../kho/FormKho';
import FormCa from '../dmca/FormCa';
import DatetimeInput from '~/components/input/DatetimeInput';

const schema = yup.object({
  kho: yup
    .object()
    .typeError('Vui lòng chọn kho')
    .required('Vui lòng chọn kho'),
  ca: yup.object().typeError('Vui lòng chọn ca').required('Vui lòng chọn ca'),
  thoi_gian_tu: yup
    .date()
    .typeError('Vui lòng chọn ngày bắt đầu')
    .required('Vui lòng chọn ngày bắt đầu'),
  thoi_gian_den: yup
    .date()
    .typeError('Vui lòng chọn ngày kết thúc')
    .required('Vui lòng chọn ngày kết thúc'),
});

const defaultData = {
  nhan_vien: null,
  nhan_vien_nhan: null,
  kho: null,
  ca: null,
  thoi_gian_tu: null,
  thoi_gian_den: null,
  tien_hang: 0,
  tien_giao: 0,
};

export default function FormGiaoCa({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        nhan_vien: defaultValues.nhan_vien
          ? {
              email: defaultValues.nhan_vien,
            }
          : null,
        nhan_vien_nhan: defaultValues.nhan_vien_nhan
          ? {
              email: defaultValues.nhan_vien_nhan,
            }
          : null,
        kho: defaultValues.ma_kho
          ? {
              ma_kho: defaultValues.ma_kho,
              ten_kho: defaultValues.ten_kho,
            }
          : null,
        ca: defaultValues.ma_ca
          ? {
              ma_ca: defaultValues.ma_ca,
              ten_ca: defaultValues.ten_ca,
            }
          : null,
        thoi_gian_tu: defaultValues.thoi_gian_tu
          ? moment(defaultValues.thoi_gian_tu).format('YYYY-MM-DD HH:mm')
          : '',
        thoi_gian_den: defaultValues.thoi_gian_den
          ? moment(defaultValues.thoi_gian_den).format('YYYY-MM-DD')
          : '',
      }
    : {};
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { kho, ca, nhan_vien, nhan_vien_nhan, ...fields } = values;
    return {
      ...fields,
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
      nhan_vien: nhan_vien?.email || '',
      nhan_vien_nhan: nhan_vien_nhan?.email || '',
      ma_ca: ca?.ma_ca || '',
      ten_ca: ca?.ten_ca || '',
    };
  };

  const handleSave = async (values, mode) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'giaoca',
      data: dataPost,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="900px"
      title="giao ca"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Box sx={{ px: 1 }}>
        <Grid container spacing="20px">
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="nhan_vien"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Nhân viên"
                  labelWidth="30%"
                  apiCode="participant"
                  placeholder="Chọn nhân viên"
                  searchFileds={['email', 'name']}
                  getOptionLabel={(option) => option.email}
                  selectedValue={value || { email: '', name: '' }}
                  value={value || { email: '', ten_nv: '' }}
                  onSelect={onChange}
                  FormAdd={FormNhanVien}
                  errorMessage={errors?.nhan_vien?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="nhan_vien_nhan"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Nhân viên nhận"
                  labelWidth="30%"
                  apiCode="participant"
                  placeholder="Chọn nhân viên"
                  searchFileds={['email', 'name']}
                  getOptionLabel={(option) => option.email}
                  selectedValue={value || { email: '', name: '' }}
                  value={value || { email: '', name: '' }}
                  onSelect={onChange}
                  FormAdd={FormNhanVien}
                  errorMessage={errors?.nhan_vien_nhan?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="kho"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label={LABEL_KHO}
                  labelWidth="30%"
                  required
                  apiCode="dmkho"
                  placeholder={`Chọn ${LABEL_KHO}`}
                  searchFileds={['ma_kho', 'ten_kho']}
                  getOptionLabel={(option) => option.ten_kho}
                  selectedValue={value || { ma_kho: '', ten_kho: '' }}
                  value={value || { ma_kho: '', ten_kho: '' }}
                  onSelect={onChange}
                  FormAdd={FormKho}
                  errorMessage={errors?.kho?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="ca"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Ca"
                  labelWidth="30%"
                  required
                  apiCode="dmca"
                  placeholder="Chọn ca"
                  searchFileds={['ma_ca', 'ten_ca']}
                  getOptionLabel={(option) => option.ma_ca}
                  selectedValue={value || { ma_ca: '', ten_ca: '' }}
                  value={value || { ma_ca: '', ten_ca: '' }}
                  onSelect={onChange}
                  FormAdd={FormCa}
                  errorMessage={errors?.ca?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="thoi_gian_tu"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  labelWidth="30%"
                  label="Thời gian từ"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.thoi_gian_tu?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="thoi_gian_den"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  labelWidth="30%"
                  label="Thời gian đến"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.thoi_gian_den?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tien_hang"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Tiền hàng"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tien_giao"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Tiền giao"
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </ModalForm>
  );
}
