import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { useTheme } from '@emotion/react';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import moment from 'moment';
import { groupBy } from '~/utils/helpers';

function HangBanChay() {
  const theme = useTheme();
  const showAlert = useAlertContext();
  const { asyncGetReport } = useApisContext();
  const [data, setData] = useState([]);

  const getReport = async () => {
    try {
      const today = moment().month(7);
      const resp = await asyncGetReport({
        apiCode: 'ctbanle',
        queryObject: {
          ma_ct: 'PBL',
          tu_ngay: today
            .clone()
            .startOf('months')
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0)
            .toISOString(),
          den_ngay: today
            .clone()
            .endOf('months')
            .hours(23)
            .minutes(59)
            .seconds(59)
            .milliseconds(999)
            .toISOString(),
        },
      });
      resp.pop();
      const products = groupBy({
        data: resp,
        callbackMatch: (item) => item.ma_vt,
      });
      const result = [];
      for (let i = 0; i < products.length; i++) {
        const data = products[i] || [];
        if (data.length > 0) {
          const tongSoLuong = data.reduce((acc, item) => {
            return acc + item.sl_xuat;
          }, 0);
          result.push({
            ten_vt: data[i].ten_vt,
            sl_xuat: tongSoLuong,
          });
        }
      }
      result.sort((a, b) => a.sl_xuat - b.sl_xuat);
      if (result.length < 20) {
        const missingNumber = 20 - result.length + 1;
        for (let i = 0; i < missingNumber; i++) {
          result.push({ ten_vt: 'SP' + (i + 1), sl_xuat: 0 });
        }
      }
      setData(result);
    } catch (error) {
      showAlert({
        type: 'error',
        message:
          error?.message || error?.error || 'Lỗi khi tải báo cáo hàng bán chạy',
      });
    }
  };

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography sx={{ fontSize: '14px', fontWeight: 550, mb: '10px' }}>
        TOP 20 HÀNG HÓA BÁN CHẠY TRONG THÁNG THEO SỐ LƯỢNG
      </Typography>
      <Box>
        {data?.length > 0 ? (
          <Typography sx={{ py: 2, textAlign: 'center' }}>
            Chưa có dữ liệu
          </Typography>
        ) : (
          <BarChart
            dataset={data}
            yAxis={[
              { scaleType: 'band', dataKey: 'ten_vt', tickPlacement: 'middle' },
            ]}
            series={[
              {
                dataKey: 'sl_xuat',
                label: 'Số lượng bán',
                color: theme.palette.primary.main,
              },
            ]}
            margin={{ top: 50, bottom: 30, right: 0 }}
            borderRadius={4}
            height={600}
            layout="horizontal"
          />
        )}
      </Box>
    </Paper>
  );
}

export default HangBanChay;
