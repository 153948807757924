import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import { Stack } from '@mui/material';
import InfoSection from './info-section/InfoSection';
import DescriptionSection from './description-section/DescriptionSection';
import ChiTietSection from './chitiet-section/ChiTietSection';
import useAlertContext from '~/hooks/hookContext/useAlertContext';

const schema = yup.object({
  ngay_ct: yup
    .date()
    .typeError('Vui lòng nhập ngày chứng từ')
    .required('Vui lòng nhập ngày chứng từ'),
  pn1: yup
    .object()
    .typeError('Vui lòng chọn phiếu mua hàng cần trả')
    .required('Vui lòng chọn phiếu mua hàng cần trả'),
});

export default function FormPN5({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const defaultData = {
    so_ct: '',
    ngay_ct: moment().format('YYYY-MM-DD HH:mm'),
    ma_kho: '',
    pn1: null,
    trang_thai: null,
    ma_kh: '',
    tk_no: 1131,
    dien_giai: '',
  };
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        ngay_ct: moment(defaultValues.ngay_ct).format('YYYY-MM-DD'),
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai,
              ten_trang_thai: defaultValues.ten_trang_thai,
            }
          : null,
      }
    : defaultData;
  const showAlert = useAlertContext();
  const { asyncSearchList, asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [details, setDetails] = useState(defaultValues?.details || []);
  // const [chiphis, setChiphis] = useState(defaultValues?.ctcpmhs || []);
  // const [vats, setVats] = useState(defaultValues?.vatvaos || []);
  const [collapses, setCollapses] = useState({
    chitiet: true,
    chiphi: true,
    mota: true,
    vat: true,
  });
  const [originPn1, setOriginPn1] = useState();

  const pn1 = watch('pn1');

  const handleReset = () => {
    reset(originalData);
    setValue('pn1', originPn1);
    setDetails(defaultValues?.details || []);
  };

  const generateDataPost = (values) => {
    const { trang_thai, pn1, ...data } = values;
    details.forEach((d) => {
      d.id_hd = pn1._id;
      delete d._id;
    });
    const result = {
      ...data,
      tk_co: '1561',
      tk_vt: '1561',
      trang_thai: trang_thai?.ma_trang_thai || '5',
      ten_trang_thai: trang_thai?.ten_trang_thai || '',
      details,
    };
    return result;
  };

  const findPn1 = async (id) => {
    try {
      const resp = await asyncSearchList({
        apiCode: 'pn1',
        condition: { q: { _id: id } },
      });
      if (resp && Array.isArray(resp) && resp.length > 0) {
        setValue('pn1', resp[0]);
        setOriginPn1(resp[0]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  const handleSave = async (values, mode) => {
    if (details?.length === 0) {
      showAlert({
        type: 'warning',
        message: 'Danh sách hàng trả trống',
      });
      return;
    }
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'pn5',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    if (pn1) {
      const { ma_kho, ma_kh } = pn1;
      setValue('ma_kh', ma_kh);
      setValue('ma_kho', ma_kho);
    } else {
      setValue('ma_kh', '');
      setValue('ma_kho', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pn1]);

  useEffect(() => {
    if (isEdit) {
      findPn1(details[0]?.id_hd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      showNavigator={false}
      width="1200px"
      title="phiêu trả hàng nhập"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack
        spacing="10px"
        sx={{
          width: '100%',
          padding: '10px',
          maxHeight: 'calc(90vh - 20px - 39px - 39px)',
          overflow: 'auto',
        }}
      >
        <InfoSection
          control={control}
          errors={errors}
          register={register}
          setDetails={setDetails}
        />
        {pn1?.details?.length > 0 && (
          <ChiTietSection
            show={collapses.chitiet}
            setCollapses={setCollapses}
            pn1={pn1}
            details={details}
            setDetails={setDetails}
          />
        )}
        <DescriptionSection
          show={collapses.mota}
          setCollapses={setCollapses}
          control={control}
          register={register}
        />
      </Stack>
    </ModalForm>
  );
}
