import React, { useState, useEffect } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ButtonBase from '~/components/button/ButtonBase';
import TextInput from '~/components/input/TextInput';
import ProfileLayout from '~/components/layouts/ProfileLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getProfile, updateProfile } from '~/redux/actions/auth.action';
import useToken from '~/hooks/useToken';
import { cloneDeep } from 'lodash';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import ImageInput from '~/components/input/ImageInput';
import { generateLinkImage } from '~/utils/helpers';

const schema = yup.object({
  name: yup.string().required('Vui lòng nhập họ và tên'),
});

function ProfilePage() {
  const profileData = useSelector((state) => state.auth.profile);
  const navigate = useNavigate();
  const token = useToken();
  const dispatch = useDispatch();
  const { uploadFile } = useApisContext();
  const showAlert = useAlertContext();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: profileData || '',
    resolver: yupResolver(schema),
  });

  const [picture, setPicture] = useState(null);

  const handleUpdate = async (values) => {
    const dataPost = cloneDeep(values);
    if (picture) {
      const formData = new FormData();
      formData.append('file', picture);
      const resp = await uploadFile({ formData, folder: 'avatars', token });
      dataPost.picture = resp?.fileUrl;
    } else if (picture === undefined) {
      dataPost.picture = '';
    }
    updateProfile({ data: dataPost, token, dispatch, showAlert });
  };

  useEffect(() => {
    getProfile({ token, dispatch, showAlert });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <ProfileLayout>
      <Stack alignItems="center">
        <Box sx={{ width: '200px', height: '200px', marginBottom: '10px' }}>
          {/* <FileInput
            url={generateLink(profileData?.picture)}
            onChange={setPicture}
          /> */}
          <ImageInput
            wrapperSx={{ width: '180px', height: '180px' }}
            url={generateLinkImage(profileData?.picture)}
            onChange={setPicture}
          />
        </Box>
        <Grid container spacing="20px">
          <Grid item xs={12} md={5}>
            <Stack spacing="20px">
              <TextInput
                labelWidth="30%"
                label="Họ và tên"
                required
                register={register}
                name="name"
                errorMessage={errors?.name?.message}
              />
              <TextInput
                disabled
                labelWidth="30%"
                label="Tài khoản"
                register={register}
                name="email"
              />
              <TextInput
                labelWidth="30%"
                label="Điện thoại"
                register={register}
                name="phone"
              />
              <TextInput
                labelWidth="30%"
                label="Zalo"
                register={register}
                name="zalo"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing="20px">
              <TextInput
                labelWidth="30%"
                label="Whatsapp"
                register={register}
                name="whatsapp"
              />
              <TextInput
                labelWidth="30%"
                label="Facebook"
                register={register}
                name="facebook"
              />
              <TextInput
                labelWidth="30%"
                label="Địa chỉ"
                register={register}
                name="address"
              />
              <TextInput
                labelWidth="30%"
                label="Email khôi phục"
                register={register}
                name="email2"
              />
            </Stack>
          </Grid>
        </Grid>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing="10px"
          sx={{ width: '100%', marginTop: '10px' }}
        >
          <ButtonBase variant="outlined" onClick={() => navigate(-1)}>
            Hủy
          </ButtonBase>
          <ButtonBase
            loading={isSubmitting}
            onClick={handleSubmit(handleUpdate)}
          >
            Cập nhật
          </ButtonBase>
        </Stack>
      </Stack>
    </ProfileLayout>
  );
}

export default ProfilePage;
