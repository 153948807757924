import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Container, Grid } from '@mui/material';
import Header from './components/header/Header';
import PrivateRoute from '~/routes/PrivateRoute';
import { API_URL, ID_APP, ORDER_HEADER_HEIGHT } from '~/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  createHd,
  initRenderHds,
  updateCurrentHd,
  updateCustomer,
  updateHds,
  updateIndexHd,
  updateOneHd,
} from '~/redux/reducrers/order.reducer';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { v4 } from 'uuid';
import Left from './components/left/Left';
import Right from './components/right/Right';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { useLocation } from 'react-router-dom';
import useAuthorize from '~/hooks/useAuthorize';
import { isArray } from '~/utils/helpers';
import { LABEL_KHO } from '~/utils/label.constant';
import axios from 'axios';
import useToken from '~/hooks/useToken';

const OrderContext = createContext();

function OrderPage() {
  const allowAuthorize = useAuthorize();
  const token = useToken();
  const { currentStore } = useSelector((state) => state.store);
  const { hds, renderHds, indexHd, currentHd } = useSelector(
    (state) => state.order
  );
  const { user } = useSelector((state) => state.auth);
  const { asyncSearchList, asyncPostData } = useApisContext();
  const showAlert = useAlertContext();
  const [, setBackdrop] = useBackdropContext();
  const [products, setProducts] = useState([]);
  const [printFrame, setPrintFrame] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  

  const getOriginCustomer = async () => {
    try {
      const resp = await asyncSearchList({
        apiCode: 'customer',
        condition: {
          page: 1,
          limit: 1,
          q: { ma_kh: currentHd?.ma_kh, kh_yn: true, ncc_yn: { $ne: true } },
        },
      });
      if (resp && isArray(resp) && resp.length > 0) {
        dispatch(updateCustomer(resp[0]));
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  const handleSelectHdInLocation = (locationHd) => {
    // Nếu chưa có hóa đơn này thì thêm nó vào
    const hdsClone = hds.filter((h) => h._id !== locationHd._id);
    (hdsClone || []).unshift(locationHd);
    dispatch(updateHds(hdsClone));
    dispatch(updateIndexHd(0));
  };

  const handleCreateHd = async () => {
    try {
      const respNv = await asyncSearchList({
        apiCode: 'dmnv',
        condition: { page: 1, limit: 1, q: { user: user?.email } },
      });
      const hd = {
        _id: v4(),
        tk_no: '13111',
        ma_kho: currentStore?.ma_kho || '',
        ten_kho: currentStore?.ten_kho || '',
        ma_nv: respNv?.[0]?.ma_nv || '',
        ten_nv: respNv?.[0]?.ten_nv || '',
        ht_thanh_toan: '',
        ten_ht_thanh_toan: '',
        print: true,
        exfields: {},
        details: [],
      };
      dispatch(createHd(hd));
      return hd;
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  const handleUpdateCurrentHd = (updateData = {}) => {
    const hdData = { ...currentHd, ...updateData };
    dispatch(updateOneHd(hdData));
  };

  const handleInitRenderHds = async () => {
    if (!currentStore || !currentStore.ma_kho) return;
    const hdsInStore = (hds || []).filter(
      (hd) => hd.ma_kho === currentStore.ma_kho
    );
    if (hdsInStore.length === 0) {
      const hd = await handleCreateHd();
      dispatch(initRenderHds([hd]));
      dispatch(updateIndexHd(0));
    } else {
      dispatch(initRenderHds(hdsInStore));
      if (!hdsInStore[indexHd]) {
        dispatch(updateIndexHd(0));
      }
    }
  };

  const print = async (idHd) => {
    try {
      setPrintFrame(null);
      const mauIn = (currentStore?.printers || []).find(
        (item) => item.ma_cn.toLowerCase() === 'pbl'
      );
      if (!mauIn) {
        showAlert({
          type: 'warning',
          message: `${LABEL_KHO} hiện tại chưa có mẫu in`,
        });
        return;
      }
      const idMauIn = mauIn.id_mau_in;
      const printUrl = `${API_URL}/${ID_APP}/pbl/excel/${idMauIn}?_id=${idHd}&print=1&access_token=${token}`;
      const resp = await axios.get(printUrl);
      let content;
      if (resp && resp.status === 200) {
        content = resp.data;
      }
      return new Promise(async (resolve) => {
        let printFrame = (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe
            onLoad={() => {
              let fr = window.frames['printframe'];
              fr.focus();
              fr.print();
              setTimeout(() => {
                resolve();
              }, 10);
            }}
            style={{ display: 'none' }}
            name="printframe"
            srcDoc={content}
          ></iframe>
        );
        setPrintFrame(printFrame);
      });
    } catch (error) {
      showAlert({
        type: 'error',
        message:
          error?.response?.data?.error ||
          error?.message ||
          error?.error ||
          'Lỗi khi in hóa đơn',
      });
    }
  };

  const saveHd = async (trang_thai = 0) => {
    try {
      setBackdrop(true);
      const { _id, details, ...fields } = currentHd;
      // const method = !!trang_thai ? asyncPutData : asyncPostData;
      const hdToSave = {
        ...fields,
        trang_thai,
        // ten_trang_thai: 'Đặt hàng',
        details: details.map((detail) => {
          const { _id, ...detailData } = detail;
          return {
            ...detailData,
            gia_ban: detailData.gia_ban_nt,
            tien: detailData.tien_nt,
            tien_ck: detailData.tien_ck_nt,
            tien_xuat: detailData.tien_nt - detailData.tien_ck_nt,
            tien_xuat_nt: detailData.tien_nt - detailData.tien_ck_nt,
          };
        }),
      };
      if (!hdToSave.ma_kh) {
        showAlert({
          type: 'warning',
          message: 'Vui lòng chọn khách hàng',
        });
        return;
      }
      if (!hdToSave.ht_thanh_toan) {
        showAlert({
          type: 'warning',
          message: 'Vui lòng chọn phương thức thanh toán',
        });
        return;
      }
      const resp = await asyncPostData({
        apiCode: 'pbl',
        data: hdToSave,
      });
      if (resp?.error) {
        showAlert({ type: 'error', message: resp?.error || 'Có lỗi xảy ra' });
        return;
      }
      const newHds = hds.filter((item) => item._id !== currentHd._id);
      dispatch(updateHds(newHds));
      showAlert({ type: 'success', message: 'Hoàn tất đơn hàng' });
      print(resp?._id);
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setBackdrop(false);
    }
  };

  const allowSelectLo = useMemo(() => {
    return user?.admin || allowAuthorize({ type: 'update', module: 'dmlo' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (hds?.length > 0) {
      handleInitRenderHds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    if (!!renderHds && renderHds.length > 0 && renderHds[indexHd]) {
      dispatch(updateCurrentHd(renderHds[indexHd]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexHd, renderHds]);

  useEffect(() => {
    if (hds) {
      handleInitRenderHds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hds]);

  useEffect(() => {
    if (location?.state) {
      handleSelectHdInLocation(location.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    if (currentHd?.ma_kh) {
      getOriginCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHd?.ma_kh]);

  return (
    <>
      {printFrame}
      <PrivateRoute>
        <OrderContext.Provider
          value={{
            handleCreateHd,
            handleUpdateCurrentHd,
            saveHd,
            allowSelectLo,
            products,
            setProducts,
          }}
        >
          {/* <GetProducts /> */}
          <Box>
            <Header headerHeight={ORDER_HEADER_HEIGHT} />
            <Box
              sx={{
                width: '100%',
                height: `calc(100vh - ${ORDER_HEADER_HEIGHT})`,
                overflow: 'auto',
                backgroundColor: 'whitish.gray',
              }}
            >
              <Box sx={{ height: '100%', padding: '5px 0' }}>
                <Container maxWidth="xl" sx={{ height: '100%' }}>
                  <Grid container spacing="5px">
                    <Grid item xs={12} md={8}>
                      <Left />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Right />
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Box>
          </Box>
        </OrderContext.Provider>
      </PrivateRoute>
    </>
  );
}

export default OrderPage;

export const useOrderContext = () => {
  const value = useContext(OrderContext);
  if (!value)
    throw new Error('Order context must be used inside Order Provider');
  return value;
};
