import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useResponsive from '~/hooks/useResponsive';
import DrawerBase from '../drawer/DrawerBase';
import TableDisplay from '../table/TableDisplay';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { isArray } from 'lodash';
import { TbTableExport } from 'react-icons/tb';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function ReportBase({ report }) {
  const showAlert = useAlertContext();
  const mdMatches = useResponsive({ matchKey: 'up', breakpoint: 'md' });
  const { asyncGetReport } = useApisContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [isChart, setIsChart] = useState(false);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [queryObject, setQueryObject] = useState({});
  const [concern, setConcern] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const getReport = async () => {
    try {
      setLoading(true);
      const resp = await asyncGetReport({
        apiCode: concern.api,
        queryObject,
      });
      if (isArray(resp)) {
        if (concern.convertData) {
          const dataConverted = concern.convertData(resp);
          setData(dataConverted);
        }
      } else {
        showAlert({
          type: 'error',
          message: resp?.message || resp?.error || 'Lỗi khi tải báo cáo',
        });
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || error?.error || 'Lỗi khi tải báo cáo',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleExportReport = () => {
    // Chuyển đổi data-table thành mảng đối tượng
    const formattedData = data.map((row) => {
      let rowData = {};
      concern.columns.forEach((col) => {
        rowData[col.name] = col.selector(row);
      });
      return rowData;
    });
    // Tạo một work sheet từ dữ liệu đã định dạng
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Tạo một work book
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Tạo buffer từ work book
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Tạo Blob từ buffer
    const dataBlob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    // Tự động tải xuống file
    saveAs(dataBlob, `Báo cáo${concern.label ? ` ${concern.label}` : ''}.xlsx`);
  };

  const renderFilter = () => (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto',
        padding: '1px',
      }}
    >
      {report.Filter && (
        <report.Filter
          setQueryObject={setQueryObject}
          setConcern={setConcern}
        />
      )}
    </Box>
  );

  useEffect(() => {
    setOpenModal(false);
    if (concern) {
      getReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concern, queryObject]);

  return (
    <>
      {concern && concern.modal && openModal && (
        <concern.modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          data={rowData}
          masterQueryObject={queryObject}
        />
      )}
      {!mdMatches && (
        <DrawerBase
          title="Điều kiện lọc"
          anchor="left"
          zIndex={1}
          open={openDrawerFilter}
          onClose={() => setOpenDrawerFilter(false)}
        >
          <Box sx={{ width: '80vw', maxWidth: '300px' }}>{renderFilter()}</Box>
        </DrawerBase>
      )}
      <Box sx={{ padding: '10px 0' }}>
        <Grid container spacing="10px" alignItems="flex-start">
          <Grid item md={2.5}>
            {renderFilter()}
          </Grid>
          <Grid item xs={12} md={9.5}>
            <Stack gap={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                  {report?.title}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    height: '30px',
                    textTransform: 'none',
                    color: 'common.white',
                  }}
                  startIcon={<TbTableExport size={16} />}
                  onClick={handleExportReport}
                >
                  Xuất báo cáo
                </Button>
              </Stack>
              <TableDisplay
                columns={concern?.columns}
                progressPending={loading}
                data={data}
                onRowClicked={(row) => {
                  setRowData(row);
                  setOpenModal(true);
                }}
                fixedHeaderScrollHeight="calc(100vh - 50px - 42px - 30px - 30px)"
              />
              {/* {isChart && concern?.showChart ? (
                <Box
                  className="hidden-scroll"
                  sx={{
                    height:
                      'calc(100vh - 50px - 42px - 10px - 30px - 10px - 42px)',
                    overflow: 'auto',
                  }}
                >
                  <BarChart
                    titleChart={report?.title}
                    labels={labels}
                    loading={loading}
                    datasets={[
                      {
                        label: concern?.labelChart || 'doanh thu',
                        backgroundColor: theme.palette.primary.main,
                        data: doanhThus,
                      },
                    ]}
                  />
                </Box>
              ) : (
                <TableDisplay
                  columns={concern?.columns}
                  progressPending={loading}
                  data={data}
                  onRowClicked={(row) => {
                    setRowData(row);
                    setOpenModal(true);
                  }}
                  fixedHeaderScrollHeight="calc(100vh - 50px - 42px - 30px - 30px)"
                />
              )} */}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ReportBase;
