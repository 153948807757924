import React from 'react';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterSelectApi from '../FilterSelectApi';
import FilterContainer from '../FilterContainer';
import { LABEL_KHO } from '~/utils/label.constant';

function FilterGiaoca({ setCondition }) {
  const originFilter = {
    nhan_vien: null,
    nhan_vien_nhan: null,
    kho: null,
    ca: null,
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    if (filter.ca) {
      condition.ma_ca = filter.ca.ma_ca;
    }
    if (filter.nhan_vien) {
      condition.nhan_vien = filter.nhan_vien.email;
    }
    if (filter.nhan_vien_nhan) {
      condition.nhan_vien_nhan = filter.nhan_vien_nhan.email;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSelectApi
          title={LABEL_KHO}
          apiCode="dmkho"
          placeholder={`Chọn một ${LABEL_KHO}`}
          selectedValue={filter.kho}
          value={filter.kho || { ma_kho: '', ten_kho: '' }}
          searchFileds={['ma_kho', 'ten_kho']}
          getOptionLabel={(option) => option.ten_kho}
          onSelect={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterSelectApi
          title="Ca"
          apiCode="dmca"
          placeholder="Chọn ca"
          selectedValue={filter.ca}
          value={filter.ca || { ma_ca: '', ten_ca: '' }}
          searchFileds={['ma_ca', 'ten_ca']}
          getOptionLabel={(option) => option.ten_ca}
          onSelect={(value) => setFilter({ ...filter, ca: value })}
        />
        <FilterSelectApi
          title="Nhân viên"
          apiCode="participant"
          placeholder="Chọn nhân viên"
          selectedValue={filter.nhan_vien}
          value={filter.nhan_vien || { email: '', name: '' }}
          searchFileds={['email', 'name']}
          getOptionLabel={(option) => option.email}
          onSelect={(value) => setFilter({ ...filter, nhan_vien: value })}
        />
        <FilterSelectApi
          title="Nhân viên nhận"
          apiCode="participant"
          placeholder="Chọn nhân viên"
          selectedValue={filter.nhan_vien_nhan}
          value={filter.nhan_vien_nhan || { email: '', name: '' }}
          searchFileds={['email', 'name']}
          getOptionLabel={(option) => option.email}
          onSelect={(value) => setFilter({ ...filter, nhan_vien_nhan: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterGiaoca;
